import { put, call } from 'redux-saga/effects';

import * as api from '../../../api/ads';
import at from '../types';

export default function* fetchDetail({ offeringId, mediaCode }) {
  try {
    const { data } = yield call(api.fetchCourseDetail, offeringId);
    yield put({ type: at.FETCH_DETAIL_SUCCESS, data, mediaCode });
  } catch (error) {
    console.log(error); //  eslint-disable-line
    yield put({ type: at.FETCH_DETAIL_FAILURE });
  }
}
