import { combineReducers } from 'redux';
import { rootReducer as ads } from './ads';
import { rootReducer as authentication } from './authentication';
import { rootReducer as courseHistory } from './courseHistory';
import { rootReducer as dashboardCards } from './dashboardCards';
import { rootReducer as notifications } from './notifications';
import { rootReducer as reportedExemptions } from './reportedExemptions';
import { rootReducer as tour } from './tour';
import { rootReducer as transcript } from './transcript';
import { rootReducer as users } from './users';
import { rootReducer as subscription } from './subscription';
import { rootReducer as subscriptions } from './subscriptions';
import { rootReducer as paymentHistory } from './paymentHistory';
import { rootReducer as inboxNotifications } from './inboxNotifications';
import { rootReducer as communications } from './communications';
import { rootReducer as nowCourses } from './nowCourses';
import { rootReducer as coursesRecommended } from './coursesRecommended';
import { rootReducer as addLicenseInfo } from './addLicenseInfo';
import { rootReducer as audits } from './audits';
import { rootReducer as discoveryTab } from './discoveryTab';
import { rootReducer as banner } from './banner';
import { rootReducer as cart } from './cart';

const rootReducer = combineReducers({
  ads,
  authentication,
  courseHistory,
  dashboardCards,
  reportedExemptions,
  tour,
  notifications,
  transcript,
  users,
  subscription,
  subscriptions,
  inboxNotifications,
  communications,
  paymentHistory,
  nowCourses,
  coursesRecommended,
  addLicenseInfo,
  audits,
  discoveryTab,
  banner,
  cart
});

export default rootReducer;
