import axios from 'axios';
import config from '../config';
const { REACT_APP_CLIENT_TRANSCRIPT_API_KEY } = process.env;

export const fetchTranscript = ({ userId, pkLicense, licenseNumber, cycleId, stateCode, professionId }) => {
  const { licenseesApiInternal } = config;

  return new Promise((res, rej) => {
    axios
      .request({
        url: `${licenseesApiInternal}/transcripts`,
        params: {
          userId,
          pkLicense,
          licenseNumber,
          cycleId,
          stateCode,
          professionId,
          useCache: true,
        },
        headers: {
          Authorization: `${REACT_APP_CLIENT_TRANSCRIPT_API_KEY}`,
        },
        method: 'GET',
      })
      .then((transcript) => {
        res(transcript);
      })
      .catch((err) => {
        rej(err);
      });
  });
};
